<template>
  <v-card flat>
    <v-card-title class="px-5 py-4">
      <div class="text-h6">Linh kiện thay thế (0)</div>
      <v-spacer></v-spacer>
      <div>
        <v-autocomplete
          v-if="offBizDetail.selected_action === 1"
          class="text-body-1 rounded-lg"
          clearable
          dense
          hide-details
          :items="filterSearchProductOptions"
          item-text="product_name"
          item-value="id"
          :menu-props="{ maxWidth: '332px' }"
          no-data-text="Không có dữ liệu"
          no-filter
          outlined
          placeholder="Tìm linh kiện"
          prepend-inner-icon="mdi-magnify"
          return-object
          :search-input.sync="productSearchKey"
          @change="selectProductOption($event)"
          :disabled="offBizDetail && offBizDetail.step === 4"
        >
          <template v-slot:item="data">
            <v-list-item-content :title="data.item.name">
              <v-list-item-title
                class="font-weight-medium"
                v-html="data.item.product_name"
              ></v-list-item-title>
              <div class="grey--text text--darken-2 text-subtitle-2">
                <span class="font-weight-bold">{{ data.item.SKU }} - </span>
                <span class="item-subtitle" v-html="data.item.name"></span>
              </div>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
    </v-card-title>

    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-5 py-0"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="offBizDetail.options"
      item-key="id"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.product_name`]="{ item }">
        <div class="my-1">
          <div class="font-weight-medium">
            {{ item.product_name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-light grey--text text--darken-2"
          >
            <span class="font-weight-bold">{{ item.SKU }} - </span>
            <span class="item-subtitle mr-1" v-html="item.name"></span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <v-autocomplete
          v-model="item.serials"
          class="tp-filter-autocomplete text-body-1 my-2"
          dense
          deletable-chips
          :items="item.allSerials ? item.allSerials : item.serials"
          item-text="serial_number"
          item-value="id"
          hide-details
          hide-selected
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn serials"
          single-line
          small-chips
          :disabled="
            offBizDetail.selected_action !== 1 ||
              (offBizDetail && offBizDetail.step === 4)
          "
          return-object
          @focus="getSerialsByOptionId(item)"
        >
        </v-autocomplete>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.serials | formatGiaVon }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.price * item.serials.length) | formatCurrency }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-hover
          v-if="offBizDetail.selected_action === 1"
          v-slot:default="{ hover }"
        >
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            small
            @click.stop="removeProductOption(item)"
          >
            <v-icon size="18px">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>
    </v-data-table>

    <v-divider class="mx-5 mb-3"></v-divider>

    <div class="px-5 pb-5">
      <v-row class="justify-md-end mx-0">
        <v-col class="pa-0" cols="12" md="6">
          <template v-if="type === 'repair'">
            <div
              class="d-flex flex-row align-end font-weight-medium justify-space-between"
            >
              <div class="">Phí sửa chữa:</div>
              <div class="font-weight-medium">
                <div class="pt-3" v-if="offBizDetail.huy_status === 1">
                  {{ offBizDetail.service_fee | formatCurrency }}
                </div>
                <tp-input-price
                  v-else
                  custom-class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
                  custom-style="width: 170px;"
                  hide-details
                  v-model="offBizDetail.service_fee"
                ></tp-input-price>
              </div>
            </div>

            <div
              class="d-flex flex-row align-end font-weight-medium justify-space-between mt-3"
            >
              <div>Tổng tiền linh kiện:</div>
              <div v-if="offBizDetail && offBizDetail.options">
                {{ offBizDetail.options | formatTotalLinhKien }}
              </div>
              <div v-else>0 đ</div>
            </div>

            <div
              class="d-flex flex-row align-end font-weight-medium justify-space-between"
            >
              <div class="">Tổng chi phí sửa chữa nội bộ:</div>
              <div class="font-weight-medium">
                <div class="pt-3">
                  <!-- {{ formatTotalPriceRepair }} -->
                  {{ offBizDetail.total_fee | formatCurrency }}
                </div>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Giá vốn",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "Tổng giá trị",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ],
      productSearchKey: null
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    filterSearchProductOptions() {
      if (this.offBizDetail.options && this.offBizDetail.options.length > 0) {
        const arr = this.offBizDetail.options.map(item => item.id);

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      }
      return this.searchProductOptions;
    },
    // formatTotalPriceRepair() {
    //   let total = 0;
    //   if (
    //     this.offBizDetail &&
    //     this.offBizDetail.options &&
    //     this.offBizDetail.options.length > 0
    //   )
    //     this.offBizDetail.options.map(item => {
    //       return (total += item.created_price ? item.created_price : 0);
    //     });
    //   total += this.offBizDetail.service_fee
    //     ? this.offBizDetail.service_fee
    //     : 0;
    //   let formatter = new Intl.NumberFormat("vi-VN", {
    //     style: "currency",
    //     currency: "VND"
    //   });
    //   return formatter.format(total);
    // },
    offBizDetail() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/offBizDetail"];
    },
    optionIds() {
      return this.offBizDetail.options.map(item => item.id);
    },
    productOptionSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    },
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    },
    suppliersRepairGuarantee() {
      return this.$store.getters["SUPPLIER/suppliers"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    },
    formatGiaVon(value) {
      let total = 0;
      value.map(item => {
        return (total += item.created_price ? item.created_price : 0);
      });
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });
      return formatter.format(total);
    },
    formatTotalLinhKien(value) {
      let total = 0;
      value.map(option => {
        option.serials.map(item => {
          return (total += item.created_price ? item.created_price : 0);
        });
      });
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });
      return formatter.format(total);
    }
  },
  watch: {
    "offBizDetail.branch_id"(newValue, oldValue) {
      if (this.$route.name.includes("detail")) {
        if (newValue !== oldValue && oldValue) {
          this.offBizDetail.options.forEach(option => {
            option.serials = [];
            option.allSerials = [];
          });
        }
        return;
      }
      this.offBizDetail.options.forEach(option => {
        option.serials = [];
        option.allSerials = [];
      });
    },
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  async created() {
    if (this.branches.length === 0) {
      this.$store.dispatch("BRANCH/getAllBranches");
    }

    await this.$store.dispatch("SUPPLIER/getSuppliers", {
      cur_page: 1,
      filter: {
        fromBalance: null,
        fromBuy: null,
        fromDate: null,
        fromReturn: null,
        sup_cate_ids: [2],
        toBalance: null,
        toBuy: null,
        toDate: null,
        toReturn: null
      },
      per_page: 50,
      search: null
    });
  },
  methods: {
    changeOffBizDetailScFee(value) {
      this.offBizDetail.sc_fee = value;
    },
    async getSerialsByOptionId(option) {
      if (option.allSerials.length === 0) {
        let res = [];

        await this.$store.dispatch(
          "SERIAL/getStockSerialsByOptionId",
          option.id
        );

        this.branches.forEach(branch => {
          // Filter serial by branch id
          let arr = this.productOptionSerials.filter(
            item => item.branch_id === branch.id
          );
          arr = arr.map(value => {
            return {
              ...value,
              disabled:
                (this.offBizDetail.branch_id &&
                  this.offBizDetail.branch_id !== branch.id) ||
                value.status !== 1
            };
          });
          // Push into res array
          if (arr.length > 0) {
            res.push({ header: `${branch.name} (${arr.length})` });
            res = res.concat(arr);
            // Push divider
            // if (index < this.branches.length - 1) {
            //   res.push({ divider: true });
            // }
          }
        });
        option.allSerials = res;
      }
    },

    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    },

    openModalPayment() {
      this.$modal.show({ name: "modal-payment" });
    },

    removeProductOption(item) {
      this.offBizDetail.options.splice(this.indexOfOptionIds(item.id), 1);
    },

    selectProductOption(item) {
      if (item && item.id) {
        this.offBizDetail.options.unshift({
          id: item.id,
          SKU: item.SKU,
          product_name: item.product_name,
          name: item.name,
          price: item.price,
          serials: [],
          allSerials: []
        });
      }
    }
  }
};
</script>
