var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"px-5 py-4"},[_c('div',{staticClass:"text-h6"},[_vm._v("Linh kiện thay thế (0)")]),_c('v-spacer'),_c('div',[(_vm.offBizDetail.selected_action === 1)?_c('v-autocomplete',{staticClass:"text-body-1 rounded-lg",attrs:{"clearable":"","dense":"","hide-details":"","items":_vm.filterSearchProductOptions,"item-text":"product_name","item-value":"id","menu-props":{ maxWidth: '332px' },"no-data-text":"Không có dữ liệu","no-filter":"","outlined":"","placeholder":"Tìm linh kiện","prepend-inner-icon":"mdi-magnify","return-object":"","search-input":_vm.productSearchKey,"disabled":_vm.offBizDetail && _vm.offBizDetail.step === 4},on:{"update:searchInput":function($event){_vm.productSearchKey=$event},"update:search-input":function($event){_vm.productSearchKey=$event},"change":function($event){return _vm.selectProductOption($event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"title":data.item.name}},[_c('v-list-item-title',{staticClass:"font-weight-medium",domProps:{"innerHTML":_vm._s(data.item.product_name)}}),_c('div',{staticClass:"grey--text text--darken-2 text-subtitle-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(data.item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle",domProps:{"innerHTML":_vm._s(data.item.name)}})])],1)]}}],null,false,62995227)}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-5 py-0",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.offBizDetail.options,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item.id) + 1)+" ")]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.SKU)+" - ")]),_c('span',{staticClass:"item-subtitle mr-1",domProps:{"innerHTML":_vm._s(item.name)}})])])]}},{key:"item.serials",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"tp-filter-autocomplete text-body-1 my-2",attrs:{"dense":"","deletable-chips":"","items":item.allSerials ? item.allSerials : item.serials,"item-text":"serial_number","item-value":"id","hide-details":"","hide-selected":"","multiple":"","no-data-text":"Không có dữ liệu","outlined":"","placeholder":"Chọn serials","single-line":"","small-chips":"","disabled":_vm.offBizDetail.selected_action !== 1 ||
            (_vm.offBizDetail && _vm.offBizDetail.step === 4),"return-object":""},on:{"focus":function($event){return _vm.getSerialsByOptionId(item)}},model:{value:(item.serials),callback:function ($$v) {_vm.$set(item, "serials", $$v)},expression:"item.serials"}})]}},{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials.length)+" ")]}},{key:"item.price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatGiaVon")(item.serials))+" ")]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.price * item.serials.length)))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(_vm.offBizDetail.selected_action === 1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeProductOption(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)}):_vm._e()]}}],null,true)}),_c('v-divider',{staticClass:"mx-5 mb-3"}),_c('div',{staticClass:"px-5 pb-5"},[_c('v-row',{staticClass:"justify-md-end mx-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[(_vm.type === 'repair')?[_c('div',{staticClass:"d-flex flex-row align-end font-weight-medium justify-space-between"},[_c('div',{},[_vm._v("Phí sửa chữa:")]),_c('div',{staticClass:"font-weight-medium"},[(_vm.offBizDetail.huy_status === 1)?_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.offBizDetail.service_fee))+" ")]):_c('tp-input-price',{attrs:{"custom-class":"font-weight-medium text-body-1 price--text-right mt-0 pt-0","custom-style":"width: 170px;","hide-details":""},model:{value:(_vm.offBizDetail.service_fee),callback:function ($$v) {_vm.$set(_vm.offBizDetail, "service_fee", $$v)},expression:"offBizDetail.service_fee"}})],1)]),_c('div',{staticClass:"d-flex flex-row align-end font-weight-medium justify-space-between mt-3"},[_c('div',[_vm._v("Tổng tiền linh kiện:")]),(_vm.offBizDetail && _vm.offBizDetail.options)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTotalLinhKien")(_vm.offBizDetail.options))+" ")]):_c('div',[_vm._v("0 đ")])]),_c('div',{staticClass:"d-flex flex-row align-end font-weight-medium justify-space-between"},[_c('div',{},[_vm._v("Tổng chi phí sửa chữa nội bộ:")]),_c('div',{staticClass:"font-weight-medium"},[_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.offBizDetail.total_fee))+" ")])])])]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }