<template>
  <div style="height: 100%">
    <!-- Start: Top -->
    <div class="d-flex flex-row align-center">
      <div class="text-h5 font-weight-bold">Xử lý sửa chữa nội bộ</div>
      <v-chip
        v-if="
          offBizDetail.selected_action === selectedActions.approval_for_sale
        "
        class="white font-weight-bold ml-3"
        color="green"
        outlined
      >
        Đã duyệt bán
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn
        v-if="offBizDetail.id && !hasCancelOrSold()"
        class="red lighten-4 red--text text-accent-2 rounded-lg"
        depressed
        :loading="statusRequest.value === 'loading-cancelGuaranteeVoucher'"
        @click="
          statusRequest.value === 'loading-cancelGuaranteeVoucher'
            ? null
            : openModalCancelingOffBiz()
        "
      >
        Hủy
      </v-btn>
      <v-btn
        v-if="offBizDetail.id && !hasCancelOrSold()"
        class="rounded-lg ml-3"
        color="primary"
        depressed
        :loading="false"
        @click="updateOffBiz(selectedActions.save)"
      >
        Lưu
      </v-btn>
      <v-btn
        v-if="!offBizDetail.id"
        class="rounded-lg"
        color="primary"
        depressed
        :loading="false"
        @click="createOffBiz"
      >
        Thêm
      </v-btn>
      <v-btn
        class="primary rounded-lg ml-3"
        dark
        depressed
        :loading="false"
        v-if="
          offBizDetail.id &&
            offBizDetail.step === stepList.length &&
            !hasCancelOrSold()
        "
        @click="updateOffBiz(selectedActions.approval_for_sale)"
      >
        Duyệt bán
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Cancel reason -->
    <v-card
      v-if="offBizDetail.selected_action === selectedActions.cancel"
      class="mt-5"
      flat
    >
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span class="font-weight-bold">Lý do hủy:</span>
        {{ offBizDetail.ly_do_huy }}
      </div>
    </v-card>
    <!-- End: Cancel reason -->
    <!-- Start: Main content -->
    <div class="main-detail--content mt-5">
      <card-replace-accessories
        :type="'repair'"
        class="card-accessories"
      ></card-replace-accessories>
      <card-processing
        class="card-processing mt-5"
        :step-list="stepList"
        :store-name="'INTERNAL_REPAIR_NOTE'"
      ></card-processing>
    </div>
    <!-- End: Main content -->
    <off-biz-cancel-modal
      v-if="offBizDetail.id"
      :homeRoute="'not-sold-warehouse_internal-repair-notes'"
      :storeName="'INTERNAL_REPAIR_NOTE'"
      :type="'sửa chữa'"
      :code="offBizDetail.id.toString()"
      :id="offBizDetail.id"
      :item="offBizDetail"
    ></off-biz-cancel-modal>
  </div>
</template>

<script>
import CardProcessing from "@/modules/NotSoldWarehouse/components/CardProcessing";
import CardReplaceAccessories from "./components/CardReplaceAccessories";
import OffBizCancelModal from "../../../../../components/modal/off-biz-cancel-modal.vue";
import { SELECTED_ACTIONS } from "../../../../../constants/constants";
export default {
  components: {
    CardProcessing,
    CardReplaceAccessories,
    OffBizCancelModal
  },
  data() {
    return {
      stepList: [
        { id: 1, name: "Chưa có hướng, chờ phương án" },
        { id: 2, name: "Đã có hướng, chờ xử lý" },
        { id: 3, name: "Đang xử lý" },
        { id: 4, name: "Đã xử lý xong" }
      ],
      selectedActions: SELECTED_ACTIONS
    };
  },
  computed: {
    offBizDetail() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/offBizDetail"];
    },
    statusRequest() {
      return this.$store.getters["INTERNAL_REPAIR_NOTE/statusRequest"];
    },

    ortherPageStatusRequest() {
      const route = this.$route;
      const { store_name } = route.query;
      return this.$store.getters[`${store_name}/statusRequest`];
    }
  },

  beforeDestroy() {
    this.$store.dispatch("INTERNAL_REPAIR_NOTE/resetOffBizDetail");
  },
  methods: {
    openModalCancelingOffBiz() {
      this.$modal.show({
        name: "cancel-offbiz-modal",
        payload: {
          item: this.offBizDetail
        }
      });
    },

    async createOffBiz() {
      // Request create
      await this.$store.dispatch(
        "INTERNAL_REPAIR_NOTE/addOffBiz",
        this.offBizDetail
      );
      // Alert and replace router
      if (this.statusRequest.value === "success-addOffBiz") {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm phiếu bảo hành thành công"
          }
        });
      }
    },

    hasCancelOrSold() {
      const { selected_action } = this.offBizDetail;
      if (
        selected_action === SELECTED_ACTIONS.cancel ||
        selected_action === SELECTED_ACTIONS.approval_for_sale
      ) {
        return true;
      }
      return false;
    },

    async updateOffBiz(selected_action) {
      // Request create
      const { step } = this.offBizDetail;
      // service_fee
      if (
        (selected_action === SELECTED_ACTIONS.tranfer ||
          selected_action === SELECTED_ACTIONS.approval_for_sale) &&
        step < this.stepList.length
      ) {
        return;
      }
      await this.$store.dispatch("INTERNAL_REPAIR_NOTE/updateOffBiz", {
        ...this.offBizDetail,
        selected_action
      });
      // Alert
      if (this.statusRequest.value === "success-updateOffBiz") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-detail--content {
  max-height: calc(100% - 36px - 20px);
  overflow-y: auto;
}
</style>
